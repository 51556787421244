import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ContactPortal } from '@app/core/models/contact-portal.model';
import {
  Validators,
  ReactiveFormsModule,
  FormBuilder,
  FormControl,
  FormGroup,
} from '@angular/forms';
import { ContactsPortalService } from './../../services/contacts-portal.service';
import { IQrResponse } from '@app/core/models/IQrResponse';
import { filter, takeUntil } from 'rxjs/operators';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { Subject } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { Listing } from '../../core/models/listing.model';
import { EntrepreunershipByIdService } from '../../services/entrepreneurship-by-id.service';
import { EntrepreunershipBySlugService } from '../../services/entrepreneurship-by-slug.service';
import { QRButtonComponent } from '../ui/qr-button/qr-button.component';
import { QRButton } from '../../core/models/qr-button.model';

@Component({
  selector: 'qr-contact-form',
  templateUrl: './qr-contact-form.component.html',
  styleUrls: ['./qr-contact-form.component.scss'],
  standalone: true,
  imports: [
    QRButtonComponent,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatInputModule,
  ],
})
export class QrContactFormComponent implements OnInit, OnDestroy {
  contactForm!: FormGroup;
  @Input() data: any;

  isFormCorrectlySent = false;

  name = new FormControl('', Validators.required);
  email = new FormControl('', [Validators.required, Validators.email]);
  phone = new FormControl('', [Validators.required]);
  message = new FormControl('', Validators.required);

  public readonly BUTTON_SUBMIT: QRButton = {
    id: 'button-login',
    hierarchy: QRButton.HIERARCHY_PRIMARY_SOLID,
    size: QRButton.SIZE_48_PX,
    value: 'listing-detail.send-email-contact',
    type: QRButton.TYPE_BUTTON,
  };

  public isButtonSubmitDisabled: boolean = true;

  private destroy$: Subject<void> = new Subject<void>();
  private idUnit: string = '';

  constructor(
    private fb: FormBuilder,
    private contactsPortalService: ContactsPortalService,
    private route: ActivatedRoute,
    private entrepreunershipBySlugService: EntrepreunershipBySlugService
  ) {}

  ngOnInit(): void {
    const SLUG: string = this.route.snapshot.params['slug'];
    this._fetchUnits(SLUG);
    this.initForm();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  initForm(): void {
    this.contactForm = this.fb.group({
      name: this.name,
      phone: this.phone,
      email: this.email,
      message: this.message,
    });
    this.contactForm.statusChanges
      .pipe(
        filter((status: string) => {
          status == 'VALID'
            ? (this.isButtonSubmitDisabled = false)
            : (this.isButtonSubmitDisabled = true);
          return this.contactForm.valid;
        })
      )
      .subscribe();
  }

  getErrorMessage(field: string): string {
    let errorMsg = '';
    switch (field) {
      case 'fullName':
        if (this.name.hasError('required'))
          errorMsg = 'Ingrese un nombre y apellido';
        break;
      case 'email':
        if (this.email.hasError('required'))
          errorMsg = 'Ingrese un email valido';
        if (this.email.hasError('email'))
          errorMsg = 'Ingrese un email con formato valido';
        break;
      case 'phone':
        if (this.phone.hasError('required')) errorMsg = 'Ingrese un teléfono';
        break;
      case 'message':
        if (this.message.hasError('required')) errorMsg = 'Ingrese un mensaje';
        break;

      default:
        errorMsg = 'error';
        break;
    }
    return errorMsg;
  }

  async onSubmit(): Promise<void> {
    if (!this.isButtonSubmitDisabled) {
      try {
        const contactPortal: ContactPortal = new ContactPortal();
        contactPortal.name = this.contactForm.get('name')?.value;
        contactPortal.phone = this.contactForm.get('phone')?.value;
        contactPortal.email = this.contactForm.get('email')?.value;
        contactPortal.message = this.contactForm.get('message')?.value;

        if (
          this.route.snapshot.routeConfig?.path?.includes('emprendimientos')
        ) {
          contactPortal.listingId = this.idUnit;
        } else {
          contactPortal.listingId = this.data;
        }

        const result: IQrResponse<any> =
          await this.contactsPortalService.addGet(contactPortal);
        if (result.code == 200) {
          this.isFormCorrectlySent = true;
        }
      } catch (error) {
        console.error('QrContactFormComponent.onSubmit: ERROR ' + error);
      }
    }
  }

  private _fetchUnits(slug: string) {
    this.entrepreunershipBySlugService
      .getData<Listing[]>(
        slug + '/' + EntrepreunershipByIdService.URL_PATHS_UNITS
      )
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (response: IQrResponse<Listing[]>) => {
          if (response.code == 200 && response.data.length > 0) {
            this.idUnit = response.data[0].id || '';
          }
        },
        error: error => {
          console.error('Error occurred:', error);
        },
      });
  }
}
