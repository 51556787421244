import { ContactStatus } from './contact-status.model';
export class ContactPortal {
  id: string = '';
  name: string = '';
  phone: string = '';
  email: string = '';
  message: string = '';
  status?: ContactStatus;
  listingId: string = '';
}
