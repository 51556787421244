import { Component, Input, OnInit } from '@angular/core';
import { Button } from '@app/core/models/button.model';
import { Icon } from '@app/core/models/icon.model';
import { Listing } from '@app/core/models/listing.model';
import { Entrepreneurship } from '@app/core/models/entrepreneurship.model';
import { Associate } from '@app/core/models/associate.model';
import { CountClicksContactsService } from '../../services/count-clicks-contacts.service';
import { environment } from '@base/environments/environment';
import { UtilsService } from '../../services/utils.service';
import { PictureSource } from '@app/core/models/picture-source';
import { Constants } from '@base/src/app/core/constants/constants';
import { ActivatedRoute } from '@angular/router';
import { QrContactFormComponent } from '../qr-contact-form/qr-contact-form.component';
import { PictureSourceComponent } from '../ui/picture-source/picture-source.component';
import { ButtonComponent } from '../ui/button/button.component';

@Component({
  selector: 'card-contact-details',
  templateUrl: './card-contact-details.component.html',
  styleUrls: ['./card-contact-details.component.scss'],
  standalone: true,
  imports: [QrContactFormComponent, PictureSourceComponent, ButtonComponent],
})
export class CardContactDetailsComponent implements OnInit {
  @Input() data!: Listing | Entrepreneurship;
  @Input() agentData: Associate | null = null;
  @Input() isEntrepreneurship: boolean = false; // TODO: is isEntrepreneurship es el entrepreneurship.id
  @Input() isProperty: boolean = false;

  buttonWhatsappSolidOnlyIcon: Button = {
    id: 'btn-green-solid',
    hierarchy: Button.HIERARCHY_GREEN_SOLID,
    size: Button.SIZE_40_PX,
    icon: Icon.NAME_WHATSAPP,
    iconOnly: true,
    iconTooltipOnly: 'Enviar WhatsApp',
  };

  buttonWhatsappSolidWithIcon: Button = {
    id: 'btn-green-solid',
    hierarchy: Button.HIERARCHY_GREEN_SOLID,
    size: Button.SIZE_40_PX,
    value: 'Whatsapp',
    icon: Icon.NAME_WHATSAPP,
  };

  buttonPhoneSolidOnlyIcon: Button = {
    id: 'btn-primary-border',
    hierarchy: Button.HIERARCHY_PRIMARY_BORDER,
    size: Button.SIZE_40_PX,
    icon: Icon.NAME_PHONE,
    iconOnly: true,
    iconTooltipOnly: 'Llamar ahora',
  };

  buttonEmailSolidOnlyIcon: Button = {
    id: 'btn-primary-border',
    hierarchy: Button.HIERARCHY_PRIMARY_BORDER,
    size: Button.SIZE_40_PX,
    icon: Icon.NAME_EMAIL,
    iconOnly: true,
    iconTooltipOnly: 'Enviar un email',
  };

  node = environment.node;
  uuidAssociateId: string = '';
  uuidInternalId: string = '';
  uuidListingTitle: string = '';
  uuidPhoneValue: string = '';
  uuidEmailValue: string = '';
  textInformationType: string = '';

  informationWhatsApp: string = '';
  urlContactWhatsApp: string = '';
  urlContactPhone: string = '';
  informationEmail: string = '';
  urlContactEmail: string = '';

  urlBaseRemax: string = environment.host;

  // Nueva información
  nameAgent: string = '';
  officeName: string = '';
  srcPhotoAgent: any;

  resizedImageWebp: string = '';

  // Picture and Source Agent resize
  agentResizedImages: PictureSource = {
    text: Constants.FOLDER_AGENTS,
    docType: 'image/' + Constants.DOCTYPE_WEBP,
    loading: PictureSource.LOADING_LAZY,
  };

  isCustomAssociateUrlVisible: boolean = false;

  constructor(
    public utilsService: UtilsService,
    private countClicksContactsService: CountClicksContactsService,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.getDataAgents();
    this.resizeAgentPhoto();
  }

  getDataAgents(): void {
    this.nameAgent = this.agentData?.name || '';
    this.officeName = this.agentData?.office.name || '';
    this.isCustomAssociateVisible();
  }

  isCustomAssociateVisible(): void {
    const customAssociate = this.route.snapshot.queryParams['associate'];

    this.isCustomAssociateUrlVisible = customAssociate != undefined;
  }

  resizeAgentPhoto(): void {
    this.srcPhotoAgent = this.agentData?.rawPhoto;
    if (this.agentData?.rawPhoto == null) {
      return;
    }
    const PHOTO_URL = this.agentData.rawPhoto;
    this.resizedImageWebp = this.utilsService.generateImageWithSize(
      PHOTO_URL,
      '180x180'
    );
  }

  countClicksContacts(type: string, event: MouseEvent): void {
    event.stopPropagation(); // Se ejecuta solo este click y evita la apertura de la card

    this.uuidAssociateId = this.agentData?.id || '';
    this.uuidInternalId = this.data.internalId;
    this.uuidListingTitle = this.data.title || '';
    if (this.agentData?.phones)
      this.uuidPhoneValue = this.agentData.phones[0].value
        .replace(/[^\w\s]/g, '')
        .replace(/\s+/g, ''); // Eliminamos los espacios
    if (this.agentData?.emails)
      this.uuidEmailValue = this.agentData.emails[0].value.replace(
        /[()\s]/g,
        ''
      ); // Eliminamos los espacios

    this.textInformationType = this.isEntrepreneurship
      ? 'la siguiente unidad del emprendimiento'
      : 'la siguiente propiedad';

    if (
      type &&
      this.uuidAssociateId &&
      this.uuidPhoneValue &&
      this.uuidEmailValue
    ) {
      const URL_CLICK: string = type + '/' + this.uuidAssociateId;
      const URL_BASE_PROPERTY =
        this.urlBaseRemax +
        `${this.isEntrepreneurship ? '/entrepreneurship/' : '/'}` +
        this.uuidInternalId;

      // WHATSAPP
      // Concatenamos la información de Whatsapp - Info + Titulo de la propiedad + La URL Base remax.com.nodo + el MLSID que es el internalId
      this.informationWhatsApp =
        'Hola ' +
        this.agentData?.name +
        ', te contacto por ' +
        this.textInformationType +
        ': ' +
        this.uuidListingTitle +
        ' ' +
        URL_BASE_PROPERTY;

      // Creamos la url del link del whatsapp con la informationWhatsapp
      this.urlContactWhatsApp =
        'https://wa.me/' +
        this.uuidPhoneValue +
        '?text=' +
        this.informationWhatsApp;
      //------------------

      // TELEFONO
      this.urlContactPhone = 'tel:' + '+' + this.uuidPhoneValue;
      //------------------

      // EMAIL
      this.informationEmail =
        this.uuidEmailValue +
        '?subject=' +
        this.uuidListingTitle +
        '&body=Hola ' +
        this.agentData?.name +
        ', te contacto por ' +
        this.textInformationType +
        ': ' +
        this.uuidListingTitle +
        ' ' +
        URL_BASE_PROPERTY;

      this.urlContactEmail = 'mailto:' + this.informationEmail;
      //------------------

      // Pasamos la URL armada que viene desde el Back
      this.countClicksContactsService.get(URL_CLICK);
    }
  }
}
