import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpUtilsService } from '../core/services/http-utils.service';

@Injectable({ providedIn: 'root' })
export class EntrepreunershipByIdService extends HttpUtilsService {
  public static readonly URL_PATHS_UNITS: string = 'units';
  constructor(http: HttpClient) {
    super(http, 'entrepreneurships/findById');
  }
}
